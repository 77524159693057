// extracted by mini-css-extract-plugin
export var backgroundImage = "ProjectHeader-module--backgroundImage--ewze8";
export var client = "ProjectHeader-module--client--jy9a2";
export var cursor = "ProjectHeader-module--cursor--lI581";
export var details = "ProjectHeader-module--details--S2CJI";
export var detailsContent = "ProjectHeader-module--detailsContent--hoqNu";
export var detailsName = "ProjectHeader-module--detailsName--poPJg";
export var hiddenGteDesktop = "ProjectHeader-module--hidden-gte-desktop--AMT9r";
export var hiddenLtDesktop = "ProjectHeader-module--hidden-lt-desktop--2QfEa";
export var mainInfo = "ProjectHeader-module--mainInfo--Z1SK4";
export var progressBar = "ProjectHeader-module--progressBar--ZDfHM";
export var progressBarInner = "ProjectHeader-module--progressBarInner--1hOAq";
export var projectHeader = "ProjectHeader-module--projectHeader--vXJ3T";
export var projectHeader_videoEnabled = "ProjectHeader-module--projectHeader_videoEnabled--1ZBXs";
export var projectHeader_videoPaused = "ProjectHeader-module--projectHeader_videoPaused--r0Byt";
export var projectHeader_videoPlaying = "ProjectHeader-module--projectHeader_videoPlaying--pW2ba";
export var title = "ProjectHeader-module--title--57GfQ";
export var videoPlayer = "ProjectHeader-module--videoPlayer--8L1Ra";
export var videoWrapper = "ProjectHeader-module--videoWrapper--1WsFa";