import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Seo from "~/components/Seo"
import ProjectHeader from "~/components/ProjectHeader"
import ProjectContent from "~/components/ProjectContent"
import PageFooter from "~/components/PageFooter"

function ProjectTemplate({ pageContext, data }) {
  const { client, title, vimeoId } = pageContext
  const {
    project: { image, director, role, createdWith, projectPageContent },
    vimeo: { videoFiles },
  } = data

  return (
    <div>
      <Seo
        title={`${client} - ${title}`}
        description={
          projectPageContent?.find((item) => item.description)?.description
        }
        image={image?.socialMediaImageUrl}
        video={videoFiles?.find((file) => file.rendition === "720p")?.link}
      />
      <ProjectHeader
        image={getImage(image)}
        client={client}
        title={title}
        director={director}
        role={role}
        createdWith={createdWith}
        videoFiles={videoFiles}
      />
      <ProjectContent content={projectPageContent} />
      <PageFooter
        button={{
          text: "Back to projects",
          isArrowBack: true,
          path: "/work",
        }}
      />
    </div>
  )
}

export const query = graphql`
  query ($title: String!, $vimeoId: String!) {
    project: datoCmsProject(title: { eq: $title }) {
      image {
        socialMediaImageUrl: url(
          imgixParams: { w: "1200", h: "630", fit: "crop" }
        )
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [
            640
            720
            800
            900
            1000
            1100
            1200
            1300
            1400
            1520
            1640
            1780
            1920
            2100
            2400
          ]
          sizes: "100vw"
          imgixParams: { q: 60 }
        )
      }
      director
      role
      createdWith: with
      projectPageContent {
        ... on DatoCmsDescription {
          description
        }
        ... on DatoCmsText {
          text
        }
        ... on DatoCmsSingleImage {
          singleImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              breakpoints: [
                640
                720
                800
                900
                1000
                1100
                1200
                1300
                1400
                1520
                1640
                1780
                1920
                2100
                2400
              ]
              sizes: "(min-width: 768px) calc(100vw - 80px), calc(100vw - 30px)"
              imgixParams: { q: 60 }
            )
          }
        }
        ... on DatoCmsImageGrid {
          images {
            image {
              oneThird: gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [
                  90
                  150
                  200
                  250
                  300
                  400
                  500
                  600
                  700
                  800
                  900
                ]
                sizes: "(min-width: 768px) calc((100vw - 160px) / 3), calc((100vw - 60px) / 3)"
                imgixParams: { q: 60 }
              )
              twoThirds: gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [
                  200
                  250
                  300
                  400
                  500
                  600
                  800
                  1000
                  1200
                  1400
                  1700
                ]
                sizes: "(min-width: 768px) calc(67vw - 80px), calc(67vw - 30px)"
                imgixParams: { q: 60 }
              )
              full: gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [
                  640
                  720
                  800
                  900
                  1000
                  1100
                  1200
                  1300
                  1400
                  1520
                  1640
                  1780
                  1920
                  2100
                  2400
                ]
                sizes: "(min-width: 768px) calc(100vw - 80px), calc(100vw - 30px)"
                imgixParams: { q: 60 }
              )
            }
            imageSize
          }
        }
      }
    }
    vimeo(id: { eq: $vimeoId }) {
      videoFiles: srcset {
        rendition
        link
      }
    }
  }
`

export default ProjectTemplate
