import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./ProjectContent.module.scss"

function ProjectContent({ content }) {
  return (
    <div className={styles.projectContent}>
      {content &&
        content.map(({ description, singleImage, text, images }, index) => {
          return (
            (description && (
              <div className={styles.largeText} key={index}>
                <p>{description}</p>
              </div>
            )) ||
            (text && (
              <div
                className={styles.mediumText}
                dangerouslySetInnerHTML={{ __html: text }}
                key={index}
              />
            )) ||
            (singleImage && (
              <GatsbyImage
                className={styles.singleImage}
                image={getImage(singleImage)}
                alt={""}
                key={index}
              />
            )) ||
            (images && (
              <div className={styles.imageGrid} key={index}>
                {images.map(
                  (
                    { image: { oneThird, twoThirds, full }, imageSize },
                    index
                  ) => (
                    <div
                      className={styles.imageGridItem}
                      data-size={imageSize}
                      key={index}
                    >
                      {(imageSize === "1/3" && (
                        <GatsbyImage
                          className={styles.singleImage}
                          image={getImage(oneThird)}
                          alt=""
                        />
                      )) ||
                        (imageSize === "2/3" && (
                          <GatsbyImage
                            className={styles.singleImage}
                            image={getImage(twoThirds)}
                            alt=""
                          />
                        )) ||
                        (imageSize === "full" && (
                          <GatsbyImage
                            className={styles.singleImage}
                            image={getImage(full)}
                            alt=""
                          />
                        ))}
                    </div>
                  )
                )}
              </div>
            ))
          )
        })}
    </div>
  )
}

export default ProjectContent
