import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import useWindowSize from "~/hooks/useWindowSize"
import Video from "~/components/Video"
import * as styles from "./ProjectHeader.module.scss"

function ProjectHeader({
  client,
  title,
  image,
  director,
  role,
  createdWith,
  videoFiles,
}) {
  const [player, setPlayer] = useState(null)
  const [isVideoEnabled, setVideoEnabled] = useState(false)
  const [isCursorVisible, setCursorVisibility] = useState(true)
  const [cursorX, setCursorX] = useState(null)
  const [cursorY, setCursorY] = useState(null)
  const [isVideoPlaying, setVideoPlaying] = useState(false)
  // const [hasInteracted, setHasInteracted] = useState(false)
  const { width: windowWidth } = useWindowSize()

  const projectHeaderStyles = `${styles.projectHeader} ${
    isVideoEnabled ? styles.projectHeader_videoEnabled : ""
  } ${
    isVideoPlaying
      ? styles.projectHeader_videoPlaying
      : styles.projectHeader_videoPaused
  }`

  function setResponsiveSrc(player, videoFiles) {
    let size = "360p"
    const isHighDpi = window.devicePixelRatio > 1
    const elementWidth = windowWidth * (isHighDpi ? 1.5 : 1)
    if (elementWidth > 1440) {
      size = "1080p"
    } else if (elementWidth > 1100) {
      size = "720p"
    } else if (elementWidth > 860) {
      size = "540p"
    }
    const newFile = videoFiles.find((file) => file.rendition === size)

    if (player.currentSrc() !== newFile.link) {
      player.src({
        type: newFile.type,
        src: newFile.link,
      })
    }
  }

  function onMouseMove(event) {
    setCursorX(event.clientX)
    setCursorY(event.clientY)
  }

  function onClick() {
    setVideoPlaying(!isVideoPlaying)
    setVideoEnabled(true)
    if (player.paused()) {
      player.play()
    } else {
      player.pause()
    }
  }

  function onPlayerReady(player, videoFiles) {
    setPlayer(player)
    setResponsiveSrc(player, videoFiles)
    player.on("timeupdate", () => {
      const progress = player.currentTime() / player.duration()
      player.el_.parentElement.style.setProperty("--progress", progress)
    })
  }

  function onProgressClick(event) {
    console.log("onProgressClick")
    const progress = cursorX / window.innerWidth
    const time = player.duration() * progress
    player.currentTime(time)
    setTimeout(() => setVideoPlaying(true), 0)
  }

  return (
    <div
      className={projectHeaderStyles}
      onMouseMove={onMouseMove}
      onMouseEnter={() => setCursorVisibility(true)}
      onMouseLeave={() => setCursorVisibility(false)}
      onClick={onClick}
    >
      <GatsbyImage className={styles.backgroundImage} image={image} alt="" />
      <div className={styles.mainInfo}>
        <h2 className={styles.client}>{client}</h2>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <dl className={styles.details}>
        {director && (
          <>
            <dt className={styles.detailsName}>Director</dt>
            <dd className={styles.detailsContent}>{director}</dd>
          </>
        )}
        {role && (
          <>
            <dt className={styles.detailsName}>Role</dt>
            <dd className={styles.detailsContent}>{role}</dd>
          </>
        )}
        {createdWith && (
          <>
            <dt className={styles.detailsName}>With</dt>
            <dd className={styles.detailsContent}>{createdWith}</dd>
          </>
        )}
      </dl>
      <div className={styles.videoPlayer}>
        <div className={styles.videoWrapper}>
          <Video
            options={{
              controls: false,
              playsinline: true,
              // sources: [
              //   {
              //     src: videoUrl["1080"],
              //     type: "video/mp4",
              //   },
              // ],
              // poster: videoPoster,
              loadingSpinner: false,
              userActions: {
                click: false,
                doubleClick: false,
                hotkeys: false,
              },
            }}
            onReady={(player) => onPlayerReady(player, videoFiles)}
          />
          <div
            className={styles.progressBar}
            onMouseEnter={() => setCursorVisibility(false)}
            onMouseLeave={() => setCursorVisibility(true)}
            onClick={onProgressClick}
          >
            <div className={styles.progressBarInner}></div>
          </div>
        </div>
      </div>
      <div
        className={styles.cursor}
        style={{
          visibility: isCursorVisible ? "visible" : "hidden",
          top: cursorY,
          left: cursorX,
        }}
      >
        {isVideoPlaying ? "Pause" : "Play"}
      </div>
    </div>
  )
}

export default ProjectHeader
