import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Context } from "~/context/Context"
import Link from "~/components/Link"
import * as styles from "./PageFooter.module.scss"

function PageFooter({ button }) {
  const { set } = useContext(Context)
  const { text, url, email, path } = button
  const to = email ? `mailto:${email}` : url || path

  const buttonStyles = `${styles.button} ${
    button.isArrowBack ? styles.button_arrowBack : ""
  }`

  function onClick(event) {
    if (to === "/work") {
      set({ isTransitionOverlayVisible: true })
      setTimeout(() => {
        navigate(to)
      }, 350)
      setTimeout(() => set({ isTransitionOverlayVisible: false }), 1200)
      event.preventDefault()
    }
  }

  return (
    <Link className={styles.pageFooter} to={to} onClick={onClick}>
      <span className={buttonStyles}>
        <span>{text}</span>
        <svg
          className={styles.buttonIcon}
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M16.1778 7.5357L10.1207 1.53269L11.1766 0.467285L19.0503 8.2707L11.1916 16.5174L10.1057 15.4826L16.2493 9.0357H0V7.5357H16.1778Z" />
        </svg>
      </span>
    </Link>
  )
}

export default PageFooter
